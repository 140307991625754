import { Controller } from "@hotwired/stimulus";
import linkifyHtml from "linkify-html";

// Connects to data-controller="linkify"
export default class extends Controller {
  connect() {
    this.linkifyContent();
  }

  linkifyContent() {
    const options = {
      target: "_blank", // Open links in new tab
      className: "auto-link" // Add a CSS class to linked elements
    };

    this.element.innerHTML = linkifyHtml(this.element.innerHTML, options);
  }
}
